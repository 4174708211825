<template>
  <div class="slide" v-if="$route.fullPath === '/'">
    <swiper
        :slides-per-view="1"
        :loop="true"
        :modules="modules"
        :navigation="{ nextEl: '.nextT', prevEl: '.prevT' }"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
    >
      <template v-for="slide in slideData">
        <swiper-slide>
          <div class="slideItem">
            <img class="fond-hero" :src="(fondHeroBlue) ? `/images/altela-img/fond-hero-blue.png` : `/images/altela-img/fond-hero.png`" />
            <img :src="`/images/altela-img/${slide.img}`"  />
            <div class="slideText">
              <div class="titleSlide">
                <h3 :class="{ 'w-100': w100 }">{{ slide.textH3 }}</h3>
              </div>
              <div class="slideToLink" v-if="slide.textLink">
            <span>
              <i class="fa-solid fa-arrow-down"></i>
            </span>
                <NuxtLink :to="slide.link">{{ slide.textLink }}</NuxtLink>
              </div>
            </div>
          </div>
        </swiper-slide>
      </template>
      <div class="slide-navigation d-flex justify-content-center gap-3 mt-4">
        <div class="prevT">
          <i class="fa-solid fa-arrow-left-long"></i>
        </div>
        <div class="nextT">
          <i class="fa-solid fa-arrow-right-long"></i>
        </div>
      </div>
    </swiper>
  </div>
  <div class="slide" v-else>
    <div class="slideItem">
      <img class="fond-hero" :src="(fondHeroBlue) ? `/images/altela-img/fond-hero-blue.png` : `/images/altela-img/fond-hero.png`" />
      <img :src="`/images/altela-img/${img}`"  />
      <div class="slideText">
        <div class="titleSlide">
          <h3 :class="{ 'w-100': w100 }">{{ textH3 }}</h3>
        </div>
        <div class="slideToLink" v-if="textLink">
            <span>
              <i class="fa-solid fa-arrow-down"></i>
            </span>
          <NuxtLink to="/">{{ textLink }}</NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation} from "swiper";

defineProps({
  img: String,
  textH3: String,
  textLink: String,
  w100: Boolean,
  fondHeroBlue: Boolean
})

const modules = ref([
  Navigation,
  Autoplay
])

const slideData = ref([
  {
    img: "/slide/1.png",
    textH3: "Filets de protection à la norme EN1263-1",
    textLink: "Voir nos filets de protection",
    link: "/detail-produit/2/211/21"
  },
  {
    img: "/slide/3.png",
    textH3: "Filets de sécurité anti chute",
    textLink: "Voir nos filets de sécurité",
    link: "/detail-produit/1/111/11"
  },
  {
    img: "/slide/2.png",
    textH3: "Filets environnement",
    textLink: "Voir nos solutions",
    link: "/detail-produit/3/311/31"
  }
])
</script>
<style scoped>
.slideItem {
  height: 519px;
  position: relative;
}
.slideItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slideItem .fond-hero {
  position: absolute;
  top: 0;
  width: 55%;
  height: 100%;
  object-fit: fill;
  opacity: 0.7;
}

.slideText {
  position: absolute;
  top: 40%;
  left: 20%;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.titleSlide {
  font-family: Panton;
  text-transform: uppercase;
  font-style: italic;
}

.titleSlide h3 {
  color: #fff;
  width: 65%;
}

.slideToLink {
  color: #fff;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: lighter;
  display: flex;
  flex-direction: column;
}

.slideToLink span i {
  transform: rotate(325deg);
}

.slideToLink a {
  padding-left: 1.5rem;
  color: inherit;
}

.slideToLink a:hover {
  text-decoration: underline;
}

.slide-navigation i {
  font-size: 30px;
  color: #D9000D;
  cursor: pointer;
}

@media screen and (max-width: 810px) {
  .slide {
    display: none;
  }
}
</style>